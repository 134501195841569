* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.CircularProgressbar-text{
  text-anchor: middle !important;
}
.large-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 10px solid rgba(218, 218, 218, 0.336);
  border-bottom-color: rgba(218, 218, 218, 0.336);
  border-left-color: rgba(218, 218, 218, 0.336);
  transform: rotate(-45deg);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shadow-cyan-custom-top {
  box-shadow: 0px -20px 20px -5px rgba(97, 97, 97, 0.1),
    /* Shadow casting upwards */ 0px 20px 20px -5px rgba(113, 114, 114, 0.1); /* Existing shadow casting downwards */
}
.large-circle img {
  transform: rotate(45deg);
  height: 100px;
}
.edit {
  color: rgb(218, 218, 218);
  font-size: 20px;
  transform: rotate(45deg);
}
.active {
  background-color: #ffedee !important;
  color: #ad2126 !important;
  font-weight: 700 !important;
}
.custom-confirm-button {
  width: 140px !important;
  height: 45px !important;
  background-color: #ff5e59 !important;
  border: none !important;
  outline: none !important;
  border-radius: 4px;
  color: white !important;
  box-shadow: none !important;
}

.custom-green-button {
  width: 140px !important;
  height: 45px !important;
  background-color: #28a745 !important;
  border: none !important;
  outline: none !important;
  border-radius: 4px;
  color: white !important;
  box-shadow: none !important;
}

.custom-red-button {
  width: 140px !important;
  height: 45px !important;
  background-color: #dc3545 !important;
  border: none !important;
  outline: none !important;
  border-radius: 4px;
  color: white !important;
  box-shadow: none !important;
}

input:focus {
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: initial !important;
  transition: background-color 5000s ease-in-out 0s;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border: 1px solid #ad2126;
}

::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
}

::-webkit-scrollbar-thumb {
  background: #ad2126;
  border: 1px solid #ad2126;
  border-radius: 130px;
}
.record-form {
  max-height: 280px;
  overflow: auto;
}

.icon-hidden {
  display: none;
}

.icon-hover:hover .icon-hidden {
  display: inline-block;
}
.audio-recorder .audio-recorder-mic {
  background-color: #f0757ae8;
  padding: 2px;
}

._dt3-T {
  min-height: 450px !important;
}
._eV_dK {
  text-align: center !important;
}

.custom-select {
  position: relative;
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  font-size: 15px;
  padding: 5px 10px 5px 10px;
  background-color: white;
  border: 1px solid #DCDCDC;
  border-radius: 10px;
  color: #1C1D1E;
  cursor: pointer;
  outline: none;
}

.custom-select select:hover {
  background: white;
  border: 1px solid #017663;
}

.custom-select::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  width: 12px;
  height: 12px;
  background-color: #1C1D1E;
  clip-path: polygon(8% 17%, 0% 25%, 50% 84%, 100% 25%, 92% 17%, 50% 65%);
}

@media screen and (min-width: 1900px) {
  .record-form {
    max-height: 300px;
    overflow: auto;
  }
}
