.checkbox-wrapper-40 {
  --borderColor: #017663;
  --borderWidth: 0.125em;
}

.checkbox-wrapper-40 label {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.checkbox-wrapper-40 input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  background: #fff;
  font-size: 15px;
  border-radius: 2px;
  display: inline-block;
  border: var(--borderWidth) solid var(--borderColor);
  width: 16px;
  height: 16px;
  position: relative;
  cursor: pointer;
}
.checkbox-wrapper-40 input[type="checkbox"]:before,
.checkbox-wrapper-40 input[type="checkbox"]:after {
  content: "";
  position: absolute;
  background: var(--borderColor);
  width: calc(var(--borderWidth) * 3);
  height: var(--borderWidth);
  top: 50%;
  left: 10%;
  transform-origin: left center;
}
.checkbox-wrapper-40 input[type="checkbox"]:before {
  transform: rotate(45deg)
    translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2))
    scaleX(0);
  transition: transform 200ms ease-in 200ms;
}
.checkbox-wrapper-40 input[type="checkbox"]:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
  transform-origin: left center;
  transition: transform 200ms ease-in;
}
.checkbox-wrapper-40 input[type="checkbox"]:checked:before {
  transform: rotate(45deg)
    translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2))
    scaleX(1);
  transition: transform 200ms ease-in;
}
.checkbox-wrapper-40 input[type="checkbox"]:checked:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
  transition: transform 200ms ease-out 200ms;
}
.checkbox-wrapper-40 input[type="checkbox"]:focus {
  outline: calc(var(--borderWidth) / 2) dotted rgba(0, 0, 0, 0.25);
}
