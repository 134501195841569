/* Button Loader 1*/
.btn-loader-1 {
  width: 28px;
  height: 28px;
  border: 3px solid #e43e43;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.btn-loader-1::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #ffff transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Button Loader 2*/
.btn-loader-2 {
  width: 28px;
  height: 28px;
  border: 3px solid #e43e43;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.btn-loader-2::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid;
  border-color: green transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Full Page Loader */
.page-loader {
  border: 4px solid rgba(210, 210, 210, 0.722);
  border-top: 4px solid #ad2126;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  animation: page-loader 700ms linear infinite;
}

@keyframes page-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
